<template>
  <div class="class_box">
    <div class="class_header">
        <van-search v-model="params.keyword" placeholder="搜索商品名称或货号" @search="onSearch"/>
    </div>
    <div class="class_sp">
       <div class="class_list">
          <div @click="getgood(index)" :class="{'class_text': true, 'class_gl': item.isflag}" v-for="(item, index) in classList" :key="index">
             {{item.name}}
          </div>
       </div>
       <div class="goodsList">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
               v-if="goodsList.length > 0"
            >
              <van-cell v-for="(item, index) in goodsList" :key="index" @click="goGoodDetails(item.id)">
                <div class="good_nav" >
                  <div class="good_img">
                    <img :src="item.topPic" alt="" srcset="">
                  </div>
                  <div class="good_info">
                    <div class="good_title">
                        {{item.name}}
                    </div>
                    <div class="tags" v-if="item.originPrice">
                        <span>限时低价</span> 
                    </div>
                    <div class="price">
                        <span class="z_price">￥{{item.price}}</span> 
                        <span class="j_price" v-if="item.originPrice">￥{{item.originPrice}}</span>
                    </div>
                  </div>
                </div>
              </van-cell>
            </van-list>
            
            <div v-if="goodsList.length == 0">
              <van-empty description="暂无商品" />
            </div>
       </div>
    </div>
    <bottomList></bottomList>
    <div class="menu">
      <menuBottom></menuBottom>
    </div>
  </div>
</template>

<script>
import menuBottom from '@/views/components/business/menuBottom'
import { getCategory, searchGood }  from '@/api/mallPage'
export default {
  name: "classify",
  components: {
    menuBottom
  },
  data() {
    return {
      params: {
        keyword: '',
        page: 1,
        rows: 20,
        cid: 0
      },
      classList: [
        {
          text: '人气',
          isflag: true,
        },{
          text: '服装',
          isflag: false,
        },{
          text: '腕表',
          isflag: false,
        },{
          text: '潮鞋',
          isflag: false,
        },{
          text: '箱包',
          isflag: false,
        },{
          text: '美妆',
          isflag: false,
        }
      ],
      goodsList: [],
      loading: false,
      finished: true,
    };
  },

  mounted() {
    if (this.$route.params.id) {
      getCategory().then(res => {
        this.classList = res.data
        res.data.forEach((item, index) => {
          if (item.id == this.$route.params.id) {
              this.getgood(index)
          }
        })
      })
    } else {
      this.getCategory()
    }
  },

  methods: {
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.params.page += 1
        this.loading = true
        searchGood(this.params).then(res => {
          this.goodsList = this.goodsList.concat(res.data.list)
            // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (res.data.list.length !== this.params.rows) {
            this.finished = true
          }
        })
      }
    },
    // 重置属性
    doReset() {
      this.params.page = 1
      this.loading = false
      this.finished = true
    },
    // 获取分类
    getCategory() {
      getCategory().then(res => {
        this.classList = res.data
        this.getgood(0)
      })
    },
    getgood(index) {
      this.doReset()
       this.classList.forEach((res, i) => {
          if(i == index) {
            this.params.cid = res.id
            this.searchGood()
            res.isflag = true
          } else {
            res.isflag = false
          }
       })
    },
    // 搜索商品
    searchGood() {
      searchGood(this.params).then(res => {
        this.goodsList = res.data.list
        if (res.data.list.length == this.params.rows) {
            this.finished = false
        }
      })
    },
    // 搜索
    onSearch() {
      this.doReset()
      this.params.cid = 0
       this.classList.forEach((res, i) => {
        res.isflag = false
       })
      searchGood(this.params).then(res => {
        this.goodsList = res.data.list
        if (res.data.list.length == this.params.rows) {
            this.finished = false
        }
      })
    },
    // 跳转商品详情
    goGoodDetails(id) {
      this.$router.push({
        name: `goodsdetail`,
        params: {
          id: id
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.menu{
    width: 100vw;
    height: 66rem;
}
.class_header{
  padding: 15rem 15rem 0rem 15rem;
}
.class_sp{
  display: flex;
  height: calc(100vh - 124rem);
  .class_list{
    width: 100rem;
    background: #F6F7FA;
    overflow-y: auto;
    .class_text{
      height: 53rem;
      line-height: 53rem;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15rem;
      color: #555555;
    }
    .class_gl{
      background: #fff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 15rem;
      color: #000000;
      position: relative;
      &::after {
      position: absolute;
      left: 0rem;
      top: 8rem;
      content: '';
      width: 4rem;
      height: 36rem;
      background: #E2BFB0;
    }
    }
  }
  .goodsList{
    width: calc(100vw - 100rem);
    background: #fff;
    overflow-y: auto;
    .good_nav{
      display: flex;
      margin-bottom: 18rem;
      .good_img{
        img {
          width: 72rem;
          height: 72rem;
          margin-left: 13rem;
        }
      }
      .good_info{
        width: calc(100% - 100rem);
        margin-left: 6rem;
        .good_title{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 13rem;
          color: #333333;
          line-height: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tags{
          margin-top: 4rem;
          span{
            padding: 2rem 6rem;
            background: rgba(187,21,21,0.1);
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #FF3C3C;
          }
        }
        .price{
          margin-top: 6rem;
          .z_price{
            font-family: DINPro, DINPro;
            font-weight: 500;
            font-size: 15rem;
            color: #000000;
          }
          .j_price{
            font-family: DINPro, DINPro;
            font-weight: 400;
            font-size: 13rem;
            color: #C5C8D3;
            line-height: 17rem;
            margin-left: 4rem;
            position: relative;
            &::after {
              position: absolute;
              left: 4rem;
              top: 8rem;
              content: '';
              width: 30rem;
              height: 1rem;
              background: #C5C8D3;
            }
          }
        }
      }
    }
  }
}
</style>
